<template>
  <div class="client-myTab6">
    <div class="client-select">
      {{lang.assDir}}
      <el-select v-model="value" :placeholder="lang.pleaseSelect">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="client-header">
      <div class="header">
        <span>{{ name }}</span>
        <el-button
          size="mini"
          icon="el-icon-plus"
          circle
          @click="addRelation"
        ></el-button>
        <el-button
          size="mini"
          icon="el-icon-delete"
          circle
          @click="delSelect"
        ></el-button>
        <el-button
          size="mini"
          icon="el-icon-refresh"
          circle
          @click="refresh"
        ></el-button>
      </div>
      <el-input
        v-model="sValue"
        :placeholder="lang.searchInputType"
        suffix-icon="el-icon-search"
        style="max-width: 300px"
        @input="searchClick"
      ></el-input>
    </div>
    <div class="client-main">
      <els-table
        ref="elsTab"
        style="height: 420px"
        :column="columnRelation"
        :data="elsData"
        @change="delCheck"
        :showCheckbox="true"
        :showIndex="false"
        :menuWidth="'80px'"
      >
        <template v-slot:menu="{ row }">
          <i class="el-icon-edit editIcon" @click="edit(row)"></i>
          <i class="el-icon-delete" @click="delRe(row)"></i>
        </template>
      </els-table>
      <!-- <div class="footer">
        <el-button @click="close">关闭</el-button>
      </div> -->
      <relation-drawer
        ref="rDrawer"
        :relation="isData"
        :relInfo="rowRel"
        @set="setsuccess"
      ></relation-drawer>
    </div>
  </div>
</template>
<script>
import elsTable from "../../../components/els-table/main.vue";
import { relation, delRelation } from "../../../api/base/client";
import relationDrawer from "../clientManage/relation-drawer.vue";
import mixinsI18n from "@/mixins/i18n.js"
export default {
  name: "client-myTab6",
  mixins: [mixinsI18n],
  components: { elsTable, relationDrawer },
  props: {
    isData: Object,
  },
  data: () => {
    return {
      PAGE_NAME: "clientManagePage",
      value: "1",
      name: "",
      sValue: "",
      relation: {},
      rowRel: {},
      delRow: [],
      elsData: [],
      copyData: [],
    };
  },
  computed: {
    columnRelation() {
      let tabs = [];
      if (this.value === "1") {
        this.name = this.lang.outAss;
        tabs = [
          { prop: "type", label: this.lang.type },
          { prop: "toType", label: this.lang.to +" "+ this.lang.enType },
          { prop: "toName", label: this.lang.to +" "+ this.lang.enName },
        ];
      } else {
        this.name = this.lang.inAss;
        tabs = [
          { prop: "type", label: this.lang.type },
          { prop: "formType", label: this.lang.from +" "+ this.lang.enType },
          { prop: "fromName", label: this.lang.from +" "+ this.lang.enName },
        ];
      }
      return tabs;
    },
    options() {
      return [
        { value: "1", label: this.lang.from },
        { value: "2", label: this.lang.to },
      ]
    }
  },
  watch: {
    value(v) {
      if (v === "1") {
        this.relation = {
          fromId: this.isData.nId.id || this.isData.id.id,
          fromType: this.isData.nId.entityType || this.isData.id.entityType,
        };
      } else {
        this.relation = {
          toId: this.isData.nId.id || this.isData.id.id,
          toType: this.isData.nId.entityType || this.isData.id.entityType,
        };
      }
      const d = { ...this.relation };
      this.onload();
    },
    sValue(v) {
      if (!!v) {
        const d = this.$refs.elsTab.dataList;
        this.elsData = d.filter((item) => {
          return (
            item.type.indexOf(v) > -1 ||
            (!!item.toName && item.toName.indexOf(v) > -1) ||
            (!!item.fromName && item.fromName.indexOf(v) > -1)
          );
        });
      } else {
        this.$refs.elsTab.page.pageNum = 1;
        this.onload();
      }
    },
  },
  created() {
    this.value = this.options[0].value;
    this.relation = {
      fromId: this.isData.nId.id || this.isData.id.id,
      fromType: this.isData.nId.entityType || this.isData.id.entityType,
    };
    this.onload();
  },
  methods: {
    searchClick() {
      this.onload();
    },
    async onload() {
      try {
        const d = { ...this.relation };
        let data = await relation(d);
        const total = data.totalCount || data.length;
        data.map((v) => {
          if (this.value === "1") {
            v.toType =
              v.to.entityType === "DEVICE"
                ? "设备"
                : v.to.entityType === "ASSET"
                ? "资产"
                : v.to.entityType === "ENTITY_VIEW"
                ? "实体视图"
                : v.to.entityType === "DASHBOARD"
                ? "租户"
                : v.to.entityType === "TENANT"
                ? "仪表板"
                : "客户";
          } else {
            v.formType =
              v.from.entityType === "DEVICE"
                ? "设备"
                : v.from.entityType === "ASSET"
                ? "资产"
                : v.from.entityType === "ENTITY_VIEW"
                ? "实体视图"
                : v.from.entityType === "DASHBOARD"
                ? "租户"
                : v.from.entityType === "TENANT"
                ? "仪表板"
                : "客户";
          }
        });
        if (!!this.sValue) {
          //查询条件不为空
          data = data.filter((els) => {
            if (!!els.type && els.type.indexOf(this.sValue) > -1) {
              return els;
            }
            if (!!els.toName && els.toName.indexOf(this.sValue) > -1) {
              return els;
            }
            if (!!els.fromName && els.fromName.indexOf(this.sValue) > -1) {
              return els;
            }
            if (!!els.formType && els.formType.indexOf(this.sValue) > -1) {
              return els;
            }
            if (!!els.toType && els.toType.indexOf(this.sValue) > -1) {
              return els;
            }
          });
        }
        this.elsData = data;
        this.copyData = data;
        return { list: data, total: total };
      } catch (e) {
        console.log(e);
      }
    },
    addRelation() {
      this.$refs.rDrawer.dialogVisible = true;
      this.rowRel = this.isData;
      this.rowRel.vSign = this.value;
      this.rowRel.tab = "add";
      this.$refs.rDrawer.title = "添加关联";
    },
    edit(v) {
      // 编辑
      v.tab = "edit";
      v.sign = this.value;
      this.$refs.rDrawer.title = "修改关联";
      if (this.value === "1") {
        v.toType =
          v.to.entityType === "DEVICE"
            ? "设备"
            : v.to.entityType === "ASSET"
            ? "资产"
            : v.to.entityType === "ENTITY_VIEW"
            ? "实体视图"
            : v.to.entityType === "DASHBOARD"
            ? "租户"
            : v.to.entityType === "TENANT"
            ? "仪表板"
            : "客户";
      } else {
        v.formType =
          v.from.entityType === "DEVICE"
            ? "设备"
            : v.from.entityType === "ASSET"
            ? "资产"
            : v.from.entityType === "ENTITY_VIEW"
            ? "实体视图"
            : v.from.entityType === "DASHBOARD"
            ? "租户"
            : v.from.entityType === "TENANT"
            ? "仪表板"
            : "客户";
      }
      this.rowRel = v;
      this.rowRel.vSign = this.value;
      this.$refs.rDrawer.dialogVisible = true;
    },
    close() {
      this.$emit("close");
    },
    delRe(v) {
      const d = {
        relationType: v.type,
        fromType: v.from.entityType,
        fromId: v.from.id,
        toId: v.to.id,
        toType: v.to.entityType,
      };
      const name = v.toName || v.fromName || "";
      this.$confirm(
        `确定删除后实体 ${name} 将取消与当前实体的关联关系。`,
        `确定要删除实体 ${name} 的关联吗?`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          delRelation(d).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.onload();
          });
        })
        .catch((err) => {
          console.log(err);
        });
      //删除
    },
    refresh() {
      this.sValue = "";
      this.onload();
    },
    setsuccess() {
      this.onload();
      // this.$refs.elsTable.apiOnload();
    },
    delSelect() {
      // 多选删除
      let num = this.delRow.length || 0;
      if (num === 0) {
        this.$message({
          message: "请先选择删除项",
          type: "warning",
        });
        return;
      }
      this.$confirm(
        `确定删除所有选择的关联关系后，与当前实体对应的所有关联关系将被移除。`,
        `确定要删除${num}个关联?`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let index = 0;
          this.delRow.forEach((item) => {
            const d = {
              relationType: item.type,
              fromType: item.from.entityType,
              fromId: item.from.id,
              toId: item.to.id,
              toType: item.to.entityType,
            };

            delRelation(d).then(() => {});
            index++;
            this.onload();
            if (index === num) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delCheck(v) {
      this.delRow = v;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
::v-deep .el-input__icon {
  line-height: 35px;
}
.client-myTab6 {
  .client-select {
    margin-bottom: 20px;
  }
  .client-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .header {
      display: flex;
      align-items: center;
      margin-left: -10px;
      span {
        margin: 0px 10px;
      }
    }
  }
  .client-main {
    .footer {
      text-align: right;
      margin-top: 20px;
    }
    .editIcon {
      margin-right: 10px;
    }
  }
}
</style>
