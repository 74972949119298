<template>
  <div class="relation-drawer">
    <el-dialog
      :title="lang.addRelation"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="30%"
      @close="resetForm('form')"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item :label="lang.assType" prop="name">
          <el-input
            v-model="form.name"
            :disabled="relInfo.tab && relInfo.tab === 'edit'"
          ></el-input>
        </el-form-item>
        <el-form-item :label="lang.type" prop="typeName">
          <el-select
            v-model="form.typeName"
            :disabled="relInfo.tab && relInfo.tab === 'edit'"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, ins) of data"
              :key="ins"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="type">
          <el-select
            v-model="form.type"
            filterable
            :disabled="relInfo.tab && relInfo.tab === 'edit'"
            :title="form.type"
          >
            <el-option
              :label="item.name"
              :value="item.code"
              v-for="(item, ins) of list"
              :key="ins"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span>{{lang.addMess}}</span>
      <els-monaca-editor
        style="height: 280px; border: 1px solid #ddd; margin-top: 20px"
        :showHeader="false"
        theme="vs-light"
        ref="editor"
        lang="json"
        v-model="form.additionalInfo"
      ></els-monaca-editor>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('form')">{{lang.cancel}}</el-button>
        <el-button type="primary" @click="confirm(form)" :loading="isSure"
          >{{lang.confirm}}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import elsForm from "../../../components/els-form/main.vue";
import { dataStyle } from "../../../assets/js/data";
import elsMonacaEditor from "../../../components/els-monaco-editor/main.vue";
import { assetType, serRelation, assetcustom } from "../../../api/base/client";
import { debounce } from "../../../utils/util.js";
import mixinsI18n from "@/mixins/i18n.js"
export default {
  name: "relation-drawer",
  mixins: [mixinsI18n],
  components: { elsForm, elsMonacaEditor },
  props: {
    relation: Object, // 对应的资产、设备的数值
    relInfo: Object, // 某一条关联数据
  },
  data: () => {
    return {
      PAGE_NAME: "clientManagePage",
      isSure: false,
      name: "",
      dialogVisible: false,
      additionalInfo: "",
      list: [],
      title: "",
      form: {
        name: "Contains",
        typeName: "",
        type: "",
        additionalInfo: "",
      },
      data: dataStyle,
    };
  },
  watch: {
    dialogVisible(v) {
      if (v) {
        if (this.relInfo.tab === "edit") {
          this.form = {};
          if (this.relInfo.vSign === "1") {
            // 从
            this.form.name = this.relInfo.type;
            this.form.typeName = this.relInfo.toType;
            this.form.type = this.relInfo.toName;
            this.form.additionalInfo = this.relInfo.additionalInfo || "";
            // this.form.additionalInfo =
            //   v.additionalInfo || ""
            //     ? JSON.parse(v.additionalInfo, null, 4)
            //     : { name: "Main_test" };
          } else {
            // 到
            this.form.name = this.relInfo.type;
            this.form.typeName = this.relInfo.formType;
            this.form.type = this.relInfo.fromName;
            this.form.additionalInfo = this.relInfo.additionalInfo || "";
            //   this.form.additionalInfo =
            //     v.additionalInfo || ""
            //       ? JSON.parse(v.additionalInfo, null, 4)
            //       : { name: "Main_test" };
          }
        }
      }
    },
    "form.typeName": {
      handler(v) {
        if (this.relInfo.tab === "edit") return;
        if (v === undefined || v === "") return;
        // this.list = [];
        // this.form.style = "";
        // if (v === "ZUHU") {
        //   v = "";
        // }
        // if (v === "customers") {
        //   console.log("customers");
        //   const d = {
        //     limit: 20,
        //     textSearch: "",
        //   };
        //   assetcustom(d).then((res) => {
        //     if (res.data.length === 0) {
        //       this.$message({
        //         message: "没有找到匹配的客户",
        //         type: "warning",
        //       });
        //     }
        //   });
        // }
        // this.form.style = "";
        const d = {
          limit: 999,
          textSearch: "",
        };
        assetType(v, d).then((res) => {
          this.list = res.data;
        });
        // if (v != "" && v !== "customers") {

        // } else {
        //   // 专门给租户用的
        //   const d = this.relInfo.tenantId.id;
        //   assetType(d).then((res) => {
        //     this.list = [];
        //     this.list.push(res);
        //   });
        // }
      },
    },
    relInfo(v) {
      // 编辑
      if (v.tab === "edit") {
        if (v.vSign === "1") {
          // 从
          this.form.name = v.type;
          this.form.typeName = v.toType;
          this.form.type = v.toName;
          this.form.additionalInfo = v.additionalInfo || "";
          // this.form.additionalInfo =
          //   v.additionalInfo || ""
          //     ? JSON.parse(v.additionalInfo, null, 4)
          //     : { name: "Main_test" };
        } else {
          // 到
          this.form.name = v.type;
          this.form.typeName = v.formType;
          this.form.type = v.fromName;
          this.form.additionalInfo = v.additionalInfo || "";
          //   this.form.additionalInfo =
          //     v.additionalInfo || ""
          //       ? JSON.parse(v.additionalInfo, null, 4)
          //       : { name: "Main_test" };
        }
      } else {
        // 新增
        // this.form = {};
      }
    },
    deep: true,
  },
  computed: {
    rules() {
      return {
        name: [
          {required: true, trigger: "blur", message: this.lang.notNull},
        ],
        typeName: [
          {required: true, trigger: "change", message: this.lang.notNull},
        ],
        type: [{required: true, trigger: "change", message: this.lang.notNull}],
      }
    }
  },
  methods: {
    confirm(form) {
      this.isSure = true;
      debounce(form, 1000);
      const r = this.relInfo;
      let id;
      const p = this.list.filter((el) => {
        return form.type === el.code;
      });
      if (p && p.length) {
        id = p[0].id;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let d;
          if (r.tab && r.tab === "edit") {
            d = {
              type: r.type,
              additionalInfo: this.form.additionalInfo,
              from: r.from,
              to: r.to,
            };
          } else {
            d = {
              type: this.form.name,
              additionalInfo: form.additionalInfo,
              from: this.relInfo.vSign === "1" ? this.relInfo.nId : id,
              to: this.relInfo.vSign === "1" ? id : this.relInfo.nId, //vSign:1 从
            };
          }
          serRelation(d)
            .then((res) => {
              this.dialogVisible = false;
              this.$emit("set");
              this.isSure = false;
            })
            .catch((err) => (this.isSure = false));
        } else {
          this.isSure = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = { name: "Contains" };
      this.dialogVisible = false;
      this.isSure = false;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-select {
  margin-right: 24px;
}
::v-deep .el-form-item:nth-child(2),
.el-form-item:nth-child(3) {
  display: inline-block;
}
</style>
